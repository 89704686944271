import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "privacy_main" }
const _hoisted_2 = { class: "top" }
const _hoisted_3 = { class: "textBox" }

import router from '@/router';
import { lang } from '@/lang';

export default /*@__PURE__*/_defineComponent({
  __name: 'privacyPolicy',
  setup(__props) {

const ret = () => {
  router.go(-1);
};

return (_ctx: any,_cache: any) => {
  const _component_van_nav_bar = _resolveComponent("van-nav-bar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_van_nav_bar, {
        onClickLeft: ret,
        title: "Privacy Policy",
        "left-arrow": "",
        border: false
      }),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p1), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p2), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p3), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p4), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p5), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p6), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p7), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p8), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p9), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p10), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p11), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p12), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p13), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p14), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p15), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p16), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p17), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p18), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p19), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p20), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p21), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p22), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p23), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p24), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p25), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p26), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p27), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p28), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p29), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p30), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p31), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p32), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p33), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p34), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p35), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p36), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p37), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p38), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p39), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p40), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p41), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p42), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p43), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p44), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p45), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p46), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p47), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p48), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p49), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p50), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p51), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p52), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p53), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p54), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p55), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p56), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p57), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p58), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p59), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p60), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p61), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p62), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p63), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p64), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p65), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p66), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p67), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p68), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p69), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p70), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p71), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p72), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p73), 1),
        _createElementVNode("p", null, _toDisplayString(_unref(lang).v.p74), 1)
      ])
    ])
  ]))
}
}

})